import React from "react";
import Layout from "../components/shared/Layout";
import { UserContext } from "../App";
import { InvoiceReport } from "../components/report/Invoice";

function InvoicePage() {

    const currentUser = React.useContext(UserContext)
    const currentUserId = currentUser && currentUser.currentUserId
    const { me } = currentUser && currentUser

    return (
        <Layout>
            <InvoiceReport currentUserId={currentUserId} me={me} />
        </Layout>
    )
}

export default InvoicePage