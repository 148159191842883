import React from "react";
import Layout from "../components/shared/Layout";

import { TaxReport } from "../components/report/Tax";
import { useSubscription } from '@apollo/react-hooks'
import { UserContext } from "../App";
import { SSCB_TAX_SUMMARY } from "../graphql/subscriptions";

function TaxPage() {

    const defaultYear = new Date().getFullYear()
    const [fiscal, setFiscal] = React.useState(defaultYear)
    const currentUser = React.useContext(UserContext)
    const currentUserId = currentUser && currentUser.currentUserId

    const variables = {
        "fiscal": fiscal,
        "user_id": currentUserId
    }
    // const { data, loading, error } = useQuery(GET_TAX_SUMMERY, { variables })
    const { data, loading } = useSubscription(SSCB_TAX_SUMMARY, {variables})

    function handleChangeFiscal(e) {
        setFiscal(e.target.value)
    }

    return (
        <Layout>
            <TaxReport data={data}
                loading={loading}
                fiscal={defaultYear}
                handleChangeFiscal={handleChangeFiscal} />
        </Layout>
    )
}

export default TaxPage