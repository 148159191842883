async function handleImageUpload(image, uploadPreset = "expensesrepo") {
  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", uploadPreset);
  data.append("cloud_name", "wklkelin");
  const response = await fetch(
    process.env.REACT_APP_CLOUDINARY_PATH,
    {
      method: "POST",
      accept: "application/json",
      body: data,
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse.url;
}

export default handleImageUpload;
