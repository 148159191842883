import React from "react";
import Layout from "../components/shared/Layout";

import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { TaxReport } from "../components/report/Tax";
import { useQuery } from '@apollo/react-hooks'
import { GET_TAX_REPORT } from '../graphql/queries'
import { UserContext } from "../App";

function ReportPage() {

    const defaultYear = new Date().getFullYear()
    const [fiscal, setFiscal] = React.useState(defaultYear)
    const currentUser = React.useContext(UserContext)
    const currentUserId = currentUser && currentUser.currentUserId

    const variables = {
        "fiscal": fiscal,
        "user_id": currentUserId
    }
    const { data, loading} = useQuery(GET_TAX_REPORT, { variables })

    function handleChangeFiscal(e) {
        setFiscal(e.target.value)
    }

    return (
        <Layout>
            <Tabs>
                <TabList>
                    <Tab>Profit and loss</Tab>
                    <Tab>Tax Summary</Tab>
                    <Tab>Tax for Business</Tab>
                    <Tab>Receipts Repo</Tab>
                </TabList>
                <TabPanel>
                    <div>coming...</div>
                </TabPanel>
                <TabPanel>
                    <div>coming...</div>
                </TabPanel>
                <TabPanel>
                    <TaxReport data={data}
                        loading={loading}
                        fiscal={defaultYear}
                        handleChangeFiscal={handleChangeFiscal} />
                </TabPanel>
                <TabPanel>
                    <div>coming...</div>
                </TabPanel>
            </Tabs>
        </Layout>
    )
}

export default ReportPage