import React from "react";
import Layout from "../components/shared/Layout";
import { Grid } from "@material-ui/core";
import { UserContext } from "../App";
import About from "../components/dashboard/About";
import ToolBar from "../components/dashboard/ToolBar";
import ExpIncList from "../components/dashboard/ExpIncList/ExpIncList";
import ExpIncForms from "../components/dashboard/ExpIncForms/ExpIncForms";
import { BalanceBoard } from "../components/dashboard/BalanceBoard";
import { useSubscription } from "@apollo/react-hooks";
import { SSCB_MINITUES } from "../graphql/subscriptions";

const searchInitial = {
    fiscal: new Date().getFullYear(),
    keyword: undefined,
    categoryId: undefined,
    paymethodId: undefined,
    month: undefined
}

function DashboardPage() { 
    const currentUser = React.useContext(UserContext);
    const currentUserId = currentUser && currentUser.currentUserId;
    const [searchState, setSearchState] = React.useState(searchInitial)
    const amountData = []
    const categoryData = []
    const paymentMethodData = []

    const variables = {
        user_id: currentUserId,
        fiscal: searchState.fiscal,
        category_id: searchState.categoryId,
        paymethod_id: searchState.paymethodId,
        keyword: searchState.keyword,
        from: searchState.month && +searchState.month+"/1/"+searchState.fiscal,
        to: searchState.month && +searchState.month < 12 ? +searchState.month+1+"/1/"+searchState.fiscal : '12/31/'+searchState.fiscal
    }
    const { data, loading } = useSubscription(SSCB_MINITUES, { variables })

    data && data.minutes.map((row, idx) => (
        amountData.push(row.amount)
    ))
    data && data.minutes.forEach(element => {
        genCategeoryDS(element)
        genPaymentMethodDS(element)
    });

    function genCategeoryDS(rows) {
        categoryData.length > 0 ? (
            categoryData.find(item => item.category === rows.category.category_name) ? (
                categoryData.map(item => item.category === rows.category.category_name && (
                    item.value = Math.abs(item.value) + Math.abs(rows.amount)
                ))

            ) : (

                categoryData.push({
                    "category": rows.category,
                    "value": rows.amount
                })
            )
        ) : (
            categoryData.push({
                "category": rows.category.category_name,
                "value": rows.amount
            })
        )
    }

    function genPaymentMethodDS(rows) {
        paymentMethodData.length > 0 ? (
            paymentMethodData.find(item => item.paymethod === rows.method.name)
                ?
                (
                    paymentMethodData.map(item => item.paymethod === rows.method.name && (
                        item.value = Math.abs(item.value) + Math.abs(rows.amount)
                    )))
                : (
                    paymentMethodData.push({
                        "paymethod": rows.method.name,
                        "value": rows.amount
                    }))
        ) : (
            paymentMethodData.push({
                "paymethod": rows.method.name,
                "value": rows.amount
            })
        )
    }

    function handleChange(e) {
        e.preventDefault()
        setSearchState({
            ...searchState,
            [e.target.name]: e.target.value
        })
    }

    function handleSearch() {
        // const keyword = searchState.keyword
    }

    return (
        <Layout>
            {currentUserId ? (
                <section>
                    <Grid container spacing={1} direction="colum">
                        <Grid item xs={12} md={6}>
                            <BalanceBoard
                                loading={loading}
                                amountData={amountData}
                                categoryData={categoryData}
                                paymentMethodData={paymentMethodData}
                                userId={currentUserId} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ExpIncForms userId={currentUserId} lookups={data} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ToolBar
                            userId={currentUserId}
                            searchState={searchState}
                            handleChange={handleChange}
                            handleSearch={handleSearch} />
                    </Grid>
                    <Grid item xs={12}>
                        <ExpIncList loading={loading} data={data} userId={currentUserId} />
                    </Grid>
                </section>
            ) : (
                <About />
            )}
        </Layout>
    );
}

export default DashboardPage;
