import React from 'react'
import { Grid, NativeSelect, TextField, Typography } from '@material-ui/core'
import { GET_PAYMETHODS_CATEGORIES } from '../../../graphql/queries'
import { useDashboardPageStyles } from '../../../styles'
import { Upload } from '../../shared/Upload'
import { useQuery } from '@apollo/react-hooks'

export function MiniuteEditing({ minutes, handleChange,setDropfiles }) {
    const classes = useDashboardPageStyles();
    const { data } = useQuery(GET_PAYMETHODS_CATEGORIES)
    const { paymethods, categories } = data

    return (
        <div>
            <Grid container space={5}>

                <Grid item xs={12} md={4}><Typography variant="body1">Amount</Typography></Grid>
                <Grid item xs={12} md={8}><TextField
                    onChange={handleChange}
                    name="amount"
                    fullWidth
                    value={minutes.amount}
                />
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Default Tax?</Typography></Grid>
                <Grid item xs={12} md={8}><TextField
                    onChange={handleChange}
                    name="tax"
                    fullWidth
                    value={minutes.tax} />
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Payment Method</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <NativeSelect
                        name="paymethodId"
                        fullWidth
                        defaultValue={minutes.method.id}
                        onChange={handleChange}>
                        {paymethods && paymethods.map(method => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Category</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <NativeSelect
                        name="categoryId"
                        fullWidth
                        defaultValue={minutes.category.id}
                        inputProps={{
                            name: 'categoryId',
                            id: 'category'
                        }}
                        onChange={handleChange}>
                        {categories && categories.map(cat => (
                            <option value={cat.id}>{cat.category_name}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Description</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Multiline"
                        name="description"
                        onChange={handleChange}
                        multiline
                        fullWidth
                        rows={3}
                        value={minutes.description}
                        placeholder="describe income detail..."
                    />
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Fiscal</Typography></Grid>
                <Grid item xs={12} md={8}><TextField
                    onChange={handleChange}
                    name="fiscal"
                    fullWidth
                    value={minutes.fiscal}
                />
                </Grid>                
                <Grid item xs={12} md={4}><Typography variant="body1">Attach Invoice</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <div className={classes.dropdown}>
                        <Upload onDrop={files => {
                            setDropfiles(files[0])
                        }} />
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}