import React from "react";
import Layout from "../components/shared/Layout";

import { useQuery } from "@apollo/react-hooks";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { GET_USERS_CATEGORIES } from "../graphql/queries";
import { UserManager } from "../components/admin/UserManager";
import { CategoryManager } from "../components/admin/CategoryManager";
import { InvoiceManager } from "../components/admin/InvoiceManager";

function AdminPage() {


    const { data, loading } = useQuery(GET_USERS_CATEGORIES)

    return (
        <Layout>
            <Tabs>
                <TabList>
                    <Tab>Users</Tab>
                    <Tab>Categories</Tab>
                    <Tab>Invoices</Tab>
                </TabList>
                <TabPanel>
                    <UserManager users={data && data.users} loading={loading} />
                </TabPanel>
                <TabPanel>
                    <CategoryManager categories={data && data.categories} loading={loading} />
                </TabPanel>
                <TabPanel>
                    <InvoiceManager invoices={data && data.invoices} loading={loading} />
                </TabPanel>
            </Tabs>

        </Layout>
    )
}

export default AdminPage