import React from "react";
import { useProfileTabsStyles,useComponentQuestionListStyles } from "../../styles";
import { Divider, Tabs, Tab, Hidden, Typography } from "@material-ui/core";
import { GridIcon, SaveIcon } from "../../icons";
import GridPost from "../shared/GridPost";
import {GET_INSTRUCTOR_TASK_LIST, GET_USER_TASK_LIST} from "../../graphql/queries"
import { useQuery } from "@apollo/react-hooks";
import LoadingScreen from "../shared/LoadingScreen";
import { useHistory } from "react-router-dom";
import { formatDateToNow } from "../../utils/formatDate";
import Image from "@material-ui/icons/Image";


function ProfileTabs({ me, user, isOwner }) {
  const classes = useProfileTabsStyles();
  const [value, setValue] = React.useState(0);
  const isInstructor = me.role === 'instructor' ? true : false
  const variables = isInstructor ? { instructorId: me.id} : {userId: me.id}
  const { data, loading } = useQuery(isInstructor ? GET_INSTRUCTOR_TASK_LIST : GET_USER_TASK_LIST, {
    variables,
    fetchPolicy: "no-cache"
  })

  if (loading) return <LoadingScreen />;
  const problems = data && data.dm_fixes

  return (
    <>
      <section className={classes.section}>
        <Hidden xsDown>
          <Divider />
        </Hidden>
        <Hidden xsDown>
          <Tabs
            value={value}
            onChange={(_, value) => setValue(value)}
            centered
            classes={{ indicator: classes.tabsIndicator }}
          >
            <Tab
              icon={<span className={classes.postsIconLarge} />}
              label="My Task(s) List"
              classes={{
                root: classes.tabRoot,
                labelIcon: classes.tabLabelIcon,
                wrapper: classes.tabWrapper,
              }}
            />
          </Tabs>
        </Hidden>
        <Hidden smUp>
          <Tabs
            value={value}
            onChange={(_, value) => setValue(value)}
            centered
            className={classes.tabs}
            classes={{ indicator: classes.tabsIndicator }}
          >
            <Tab
              icon={<GridIcon fill={value === 0 ? "#3897f0" : undefined} />}
              classes={{ root: classes.tabRoot }}
            />
            {isOwner && (
              <Tab
                icon={<SaveIcon fill={value === 1 ? "#3897f0" : undefined} />}
                classes={{ root: classes.tabRoot }}
              />
            )}
          </Tabs>
        </Hidden>
        <Hidden smUp>{problems && <Divider />}</Hidden>
        {value === 0 && <ProfilePosts problems={problems} user={user} isOwner={isOwner} />}
        {value === 1 && <SavedPosts user={user} />}
      </section>
    </>
  );
}

function ProfilePosts({ problems, user, isOwner }) {
  const classes = useProfileTabsStyles();

  return (
    <article className={classes.article}>
      <div>
        {problems && problems.map((row, index) => (
          <Problem
            key={index}
            question={row}
            />
        ))}
      </div>
    </article>
  );
}

function Problem({ question, openDialog = false }) {
  const history = useHistory();
  const classes = useComponentQuestionListStyles();

  return (
      <div key={question.id}
          onClick={() => { history.push(`/problem/${question.problemId}`) }}
          className={classes.questoinList}
          >
          <span className={classes.cell1} component="th">
              {makeProblemTitle(question.post)}
          </span>
          <span className={classes.cell2} style={{ fontSize: 10 }}>
              {formatDateToNow(question.createdAt)}
          </span>
          <span className={classes.cell3}>{question.status}</span>
          <span
              className={classes.cell3}
              style={{ opacity: !question.solutionFile && 0.3 }}>
              <Image src={question.solutionFile} className={classes.thumbnail} />
          </span>
      </div>
  );
}

function makeProblemTitle(problem) {
  return problem.toString().length > 30 
  ? problem.toString().substring(0,300) + ' ...'
  : problem
}

function SavedPosts({ user }) {
  const classes = useProfileTabsStyles();

  if (user.saved_posts.length === 0) {
    return (
      <section className={classes.savedPostsSection}>
        <div className={classes.noContent}>
          <div className={classes.savePhotoIcon} />
          <Typography variant="h4">Save</Typography>
          <Typography align="center">
            Save photos and videos that you want to see again. No one is
            notified, and only you can see what you've saved.
          </Typography>
        </div>
      </section>
    );
  }

  return (
    <article className={classes.article}>
      <div className={classes.postContainer}>
        {user.saved_posts.map(({ post }) => (
          <GridPost key={post.id} post={post} />
        ))}
      </div>
    </article>
  );
}

export default ProfileTabs;
