import React from "react";
import Layout from "../components/shared/Layout";
import {
    Button,
    Grid,
    Typography,

} from '@material-ui/core'
import { useComponentPriceStyle } from "../styles";
import { PaymentOutlined } from "@material-ui/icons";

function PricePage() {

    const classes = useComponentPriceStyle()

    return (
        <Layout>
            <Typography variant="h4" color="primary">
                We will serve our clients full services, features will be all inclusive for all type of members, and new features will be keep adding. 
                No obligation, cancel anytime.
                </Typography>
            <Grid container justify="center">
                <Grid item xs={12} md={5}
                    className={classes.priceBox}>
                    <Typography variant="h4"><PaymentOutlined fontSize="large" /> Monthly Payment</Typography>
                    <Typography variant="h6">$10/month</Typography>
                    <ul>
                    <li>Track income & expenses</li>
                        <li>Track payroll</li>
                        <li>Track sales & sales tax</li>
                        <li>Capture & organize receipts</li>
                        <li>Track mileage use</li>
                        <li>Run reports</li>
                        <li>Invoice & accept payments</li>
                        <li>Maximize tax deductions</li>
                        <li>Manage bills & payments</li>
                        <li>Track time</li>
                        <li>Track project profitability</li>
                        <li>Track inventory</li>
                        <li>new features keep coming...</li>
                    </ul>
                    <Button fullWidth color="primary" variant="contained">Pay Monthly</Button>
                </Grid>
                
                <Grid item xs={12} md={5}
                    className={classes.priceBox}>
                    <Typography variant="h4"><PaymentOutlined fontSize="large" /> Annually Payment</Typography>
                    <Typography variant="h6">$100/12 month</Typography>

                    <ul>
                        <li>Track income & expenses</li>
                        <li>Track payroll</li>
                        <li>Track sales & sales tax</li>
                        <li>Capture & organize receipts</li>
                        <li>Track mileage use</li>
                        <li>Run reports</li>
                        <li>Invoice & accept payments</li>
                        <li>Maximize tax deductions</li>
                        <li>Manage bills & payments</li>
                        <li>Track time</li>
                        <li>Track project profitability</li>
                        <li>Track inventory</li>
                        <li>new feature keep coming...</li>
                    </ul>
                    <Button fullWidth color="secondary" variant="contained">Pay Yearly</Button>
                </Grid>
            </Grid>

            <Grid container>
                <Typography variant="h4" color="primary">Why Cloud Based ExpensesRepo is Right for You!</Typography>

                <Grid item sm={12} md={5} style={{ backgroundColor: "lightgrey", padding: 10, margin: 10 }}>
                    <Typography variant="h5" color="primary">Access anywhere, on any device</Typography>
                    <Typography variant="body1">
                        Your data is automatically synced across your devices so you can access it from anywhere. It also means you can run your business from the office, the car or the kitchen table.
                </Typography>
                </Grid>
                <Grid item sm={12} md={5} style={{ backgroundColor: "lightgrey", padding: 10 , margin: 10}}>
                    <Typography variant="h5" color="primary">Always up-to-date</Typography>
                    <Typography variant="body1">
                        We are constently upgrading with every new release. With ExpenseRepo cloud tracking software, you will be served simply with the latest version anytime and anywhere.
                </Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default PricePage