import React from 'react'

function useMatchMedia() {
    let initDivice = window.matchMedia("(max-width: 760px")
    const [isMobile, setIsMobile] = React.useState(initDivice.matches)

    React.useEffect(() => {
        
        function handler() {
            setIsMobile(_isMobile.matches)
        }
        
        const _isMobile = window.matchMedia("(max-width: 760px)")
        _isMobile.addEventListener("change", handler)

        return () => {
            _isMobile.removeEventListener("change", handler)
        }
    },[])

    return (
        isMobile
    )
}

export default useMatchMedia