import React from 'react'
import { CircularProgress, Grid, NativeSelect, TableFooter, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        display: 'flex',
        alignItems: "center",
        padding: 20
    }
});

export function TaxReport({ data, loading, handleChangeFiscal, fiscal }) {
    const classes = useStyles();
    const oYears = []
    for (let index = 2020; index <= fiscal; index++) {
        oYears.push(index)
    }

    // calculation
    let total_business_income = 0
    let total_business_income_tax = 0
    let total_business_expenses = 0
    let total_business_expenses_tax = 0
    let total_business_deduction = 0
    let total_business_deduction_tax = 0


    data && data.categories.map(row => row.category_code === '8801' && (
            total_business_income += Math.abs(row.minutes_aggregate.aggregate.sum.amount)
    ))
    data && data.categories.map(row => row.category_code === '8801' && (
            total_business_income_tax += Math.abs(row.minutes_aggregate.aggregate.sum.tax)
    ))
    data && data.categories.map(row => row.category_code !== '8801' && (
        total_business_expenses += Math.abs(row.minutes_aggregate.aggregate.sum.amount)
    ))
    data && data.categories.map(row => row.category_code !== '8801' && (
        total_business_expenses_tax += Math.abs(row.minutes_aggregate.aggregate.sum.tax)
    ))
    data && data.categories.map(row => row.category_code !== '8801' && (
        total_business_deduction += Math.abs(row.minutes_aggregate.aggregate.sum.amount) * Math.abs(row.adjustment_rate) / 100
    ))
    data && data.categories.map(row => row.category_code !== '8801' && (
        total_business_deduction_tax += Math.abs(row.minutes_aggregate.aggregate.sum.tax) * Math.abs(row.adjustment_rate) / 100
    ))

    const total_business_net_income = total_business_income - total_business_deduction
    const total_business_net_income_tax = total_business_income_tax - total_business_deduction_tax

    return (
        <div classes={classes.container}>
            <Grid container direction="row" justify="center">
                <Typography variant="h6"> Tax Year </Typography>{" "}
                <NativeSelect
                    name="fiscal"
                    onChange={handleChangeFiscal}
                    defaultValue={fiscal}
                >
                    {oYears.map(m => (
                        <option value={m}>{m}</option>
                    ))}
                </NativeSelect>
            </Grid>
            <Typography variant="h6" color="primary">Tax Summery</Typography>
            <Grid container spacing={2} >
                <Grid item sm={12} md={12}>
                    <Typography variant="h6" color="primary">Business Income</Typography>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Income (no tax)</TableCell>
                                        <TableCell>Tax</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? <CircularProgress /> :
                                        data && data.categories.map(row => (

                                            row.category_code === '8801' && (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {row.category_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <NumberFormat value={row.minutes_aggregate.aggregate.sum.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            prefix={'$'} /> */}
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat value={row.minutes_aggregate.aggregate.sum.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            prefix={'$'} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat value={row.minutes_aggregate.aggregate.sum.tax}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            prefix={'$'} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        ))
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="h6">TOTAL BUSINESS INCOME/TAX</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {/* <NumberFormat value={total_business_income}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                prefix={'$'} /> */}
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat value={total_business_income}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                prefix={'$'} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat value={total_business_income_tax}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                prefix={'$'} />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>

                </Grid>
                <Grid item sm={12} md={12}>
                    <Typography variant="h6" color="primary">Business Expenses/Tax</Typography>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Expense (no tax)</TableCell>
                                        <TableCell>Deduction</TableCell>
                                        <TableCell>Tax</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading && <CircularProgress />}
                                    {data && data.categories.map(row => (
                                        row.category_code !== '8801' && (
                                            <TableRow key={row.category_code}>
                                                <TableCell component="th" scope="row">
                                                    {row.category_name}
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat value={Math.abs(row.minutes_aggregate.aggregate.sum.amount)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix={'$'} />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat value={Math.abs(row.minutes_aggregate.aggregate.sum.amount) * Math.abs(row.adjustment_rate) / 100}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix={'$'} />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat value={Math.abs(row.minutes_aggregate.aggregate.sum.tax) * Math.abs(row.adjustment_rate) / 100}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix={'$'} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="h6">TOTAL EXPENSES/TAX</Typography>
                                        </TableCell>
                                        <TableCell><NumberFormat value={total_business_expenses}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} /></TableCell>
                                        <TableCell><NumberFormat value={total_business_deduction}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} /></TableCell>
                                        <TableCell><NumberFormat value={total_business_deduction_tax}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} /></TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>

                </Grid>

                <Grid item sm={12} md={12}>
                    <Typography variant="h6" color="primary">Net Income or Loss</Typography>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Income Loss(no tax)</TableCell>
                                        <TableCell>Tax</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Total Taxable Business Income
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><NumberFormat value={total_business_income}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} />
                                        </TableCell>
                                        <TableCell><NumberFormat value={total_business_income_tax}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Total Busienss Expenses
                                        </TableCell>
                                        <TableCell>
                                        {'   '}
                                        </TableCell>
                                        <TableCell><NumberFormat value={total_business_deduction}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} />
                                        </TableCell>
                                        <TableCell><NumberFormat value={total_business_deduction_tax}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            prefix={'$'} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="h6">NET INCOME LOSS/TAX(OWING/REFUND)</Typography>
                                        </TableCell>
                                        <TableCell>{'   '}</TableCell>
                                        <TableCell>
                                            <Typography variant="h6" color={total_business_net_income > 0 ? 'primary' : "secondary"}>
                                                <NumberFormat value={total_business_net_income}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'} />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" color={total_business_net_income > 0 ? 'primary' : "secondary"}>
                                                <NumberFormat value={total_business_net_income_tax}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'} />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </div >
    )
}