
import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useDashboardPageStyles } from "../../styles";
import banner2 from '../../images/banner2.jpg'
import { Button, Typography } from "@material-ui/core";
import { Image } from "@material-ui/icons";

export default function About() {
    const classes = useDashboardPageStyles()

    function goto() {
        window.location.assign('/accounts/emailsignup')
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                    <Image src={banner2} className={classes.banner} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5">
                            Sign-Up today for
                            30 days <span className={classes.green}>FREE</span> none blocking trial
                            this all-in-one expenses organizer, free cancellation & 
                            no credit card will be on holding
                        </Typography>
                        <Button variant="contained" color="primary" size="large"
                            onClick={goto}
                            disableElevation style={{ margin: 22 }}>
                            SIGN UP NOW
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <ul>
                            <li>Your data you own</li>
                            <li>Never share any data</li>
                            <li>You full access 247</li>
                            <li>Web, Mobile & Tablet</li>
                            <li>free cancellation</li>
                            <li>No credit card holding</li>
                        </ul>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <ul>
                            <li>Track income & expenses</li>
                            <li>Track receipts</li>
                            <li>Track mileages</li>
                            <li>Track invoices</li>
                            <li>Track Estimates </li>
                        </ul>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <ul>
                            <li>Tax summery report</li>
                            <li>GST/HST report</li>
                            <li>Profit & Loss report</li>
                        </ul>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <ul>
                            <li>Track projects</li>
                            <li>Track inventory</li>
                            <li>Small Biz Payroll</li>
                            <li>Features keep coming...</li>
                        </ul>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

