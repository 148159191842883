import { gql } from "apollo-boost";


export const SSCB_TAX_SUMMARY = gql`
subscription sscbTaxSummery($user_id: uuid!, $fiscal: Int) {
  categories(where: {display: {_eq: true}}, order_by: {category_name: asc}) {
    category_name
    category_code
    adjustment_rate
    minutes_aggregate(where: {_and: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}}}) {
      aggregate {
        sum {
          amount
          tax
        }
      }
    }
  }
}`

export const SSC_INVOICE_LIST = gql`
  subscription sscbInvoiceList($user_id: uuid!) {
    invoices(
      where: { user_id: { _eq: $user_id } }
      order_by: { invoice_no: desc }
    ) {
      sent
      paid
      to_address
      to_company
      to_email
      to_name
      to_phone
      item
      description
      unit_price
      total_net_price
      tax_amount
      total_amount
      count
      invoice_date
      invoice_no
      id
      user {
        name
        email
        website
        id
      }
    }
  }
`;

export const SSCB_GET_BALANCE = gql`
  subscription getBalance($fiscal: Int!, $user_id: uuid!) {
    minutes_aggregate(
      where: { _and: { fiscal: { _eq: $fiscal }, user_id: { _eq: $user_id } } }
      order_by: { created_at: desc }
    ) {
      aggregate {
        sum {
          amount
          tax
        }
      }
    }
  }
`;

export const SSCB_MINITUES = gql`
  subscription SUB_MINUTES(
    $user_id: uuid!
    $fiscal: Int!
    $category_id: uuid
    $paymethod_id: uuid
    $description: String
    $from: timestamptz
    $to: timestamptz
  ) {
    minutes(
      where: {
        user_id: { _eq: $user_id }
        fiscal: { _eq: $fiscal }
        category_id: { _eq: $category_id }
        paymethod_id: { _eq: $paymethod_id }
        created_at: {_gte: $from, _lt: $to}
      }
      order_by: { created_at: desc }
    ) {
      id
      amount
      created_at
      description
      fiscal
      item
      tax
      updated_at
      attachment_url
      category {
        category_name
        id
      }
      method {
        name
        id
      }
    }
  }
`;

export const SSCB_INSTRUCTOR_FIX_LIST = gql`
  subscription sscbInstructorFixList($problemId: uuid!) {
    dm_fixes(
      where: { problemId: { _eq: $problemId } }
      order_by: { createdAt: desc }
    ) {
      id
      post
      problemId
      solutionFile
      userId
      createdAt
      instructorId
      status
    }
  }
`;

export const SSCB_ALL_QUESTIONS = gql`
  subscription sscbAllQuestions {
    dm_questions(order_by: { createdAt: desc }, limit: 15) {
      createdAt
      id
      instructorId
      status
      studentEmail
      studentId
      problem
      category
      days
      problemFile
    }
  }
`;

export const ME = gql`
  subscription me($userId: String) {
    users(where: { user_id: { _eq: $userId } }) {
      id
      user_id
      name
      username
      email
      profile_image
      created_at
      last_checked
      role
    }
  }
`;

export const GET_POST = gql`
  subscription getPost($postId: uuid!) {
    posts_by_pk(id: $postId) {
      id
      caption
      created_at
      media
      location
      user {
        id
        username
        name
        profile_image
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      likes {
        id
        user_id
      }
      saved_posts {
        id
        user_id
      }
      comments(order_by: { created_at: desc, user: { username: asc } }) {
        id
        content
        created_at
        user {
          username
          profile_image
        }
      }
    }
  }
`;
