import React from 'react';
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatPostDate } from '../../utils/formatDate';
import { Image } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 600,
    },
});


export function InvoiceManager({loading, invoices}) {
    const classes = useStyles();

    function converter(input, user) {
        switch (input) {
            case 'profile_image':
                // eslint-disable-next-line react/jsx-no-undef
                return <Image src={user[input]} style={{width:25}} /> 
            case 'created_at':
                return formatPostDate(user[input])
            case 'user':
                return user["user"].name
            default:
                return user[input]
        }
    }

    return (
        <>
            {loading && <CircularProgress />}
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {invoices && Object.keys(invoices[0]).map((k, i) =>
                                    <TableCell
                                        key={i}
                                    >
                                        {k}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                            <>
                                {invoices && invoices.map((user, idx) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                                        <>
                                            {Object.keys(user).map(m =>
                                                <TableCell key={idx}>
                                                    { converter(m, user) }
                                                </TableCell>
                                            )}
                                        </>
                                    </TableRow>
                                ))}
                            </>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}