import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import {
  AddRounded,
  Cancel,
  Check,
  Delete,
  Email,
  Payment,
  Save,
  SendRounded,
  Update,
} from "@material-ui/icons";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import { SSC_INVOICE_LIST } from "../../graphql/subscriptions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatPostDate } from "../../utils/formatDate";
import {
  ADD_INVOICE,
  DELETE_MINUTE,
  UPDATE_INVOICE,
} from "../../graphql/mutations";
import sendSGEmail from "../../utils/handleSGEmailer";
import StoreNotification from "../../utils/storeNotification";

const useStyles = makeStyles({
  table: {
    maxWidth: 850,
  },
});

const formIntial = {
  invoice_no: "",
  sent: false,
  to_address: "",
  to_company: "",
  to_email: "",
  to_name: "",
  to_phone: "",
  item: "",
  description: "",
  unit_price: "",
  total_net_price: "",
  tax_amount: "",
  total_amount: "",
  user_id: "",
};

export function InvoiceReport({ currentUserId, me }) {
  const classes = useStyles();
  const variables = { user_id: currentUserId && currentUserId };
  const { data, loading } = useSubscription(SSC_INVOICE_LIST, { variables });
  const [addNewInvoice] = useMutation(ADD_INVOICE);
  const [deleteInvoice] = useMutation(DELETE_MINUTE);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);

  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  const [formData, setFormData] = React.useState(formIntial);

  const handleClickOpen = () => {
    setFormData(formIntial);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
  };

  function handleChange(e) {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function handleClickEditOpen(row) {
    setFormData(row);
    setEditOpen(true);
  }

  async function handleSave() {
    const variables = {
      invoice_no: new Date().getTime().toString(),
      to_address: formData.to_address,
      to_company: formData.to_company,
      to_email: formData.to_email,
      to_name: formData.to_name,
      to_phone: formData.to_phone,
      item: formData.item,
      description: formData.description,
      unit_price: Math.abs(formData.unit_price),
      total_net_price: Math.abs(formData.total_net_price),
      tax_amount: Math.abs(formData.tax_amount),
      total_amount: Math.abs(formData.total_amount),
      user_id: currentUserId,
      sent: false,
    };
    await addNewInvoice({ variables });
    handleClose();
  }

  async function handleDelete() {
    const variables = {
      id: formData.id,
      user_id: currentUserId,
    };

    await deleteInvoice({ variables });
    handleClose();
  }

  async function handleUpdate(isSent = "") {
    const variables = {
      id: formData.id,
      user_id: currentUserId,
      to_address: formData.to_address,
      to_company: formData.to_company,
      to_email: formData.to_email,
      to_name: formData.to_name,
      to_phone: formData.to_phone,
      item: formData.item,
      description: formData.description,
      unit_price: Math.abs(formData.unit_price),
      total_net_price: Math.abs(formData.total_net_price),
      tax_amount: Math.abs(formData.tax_amount),
      total_amount: Math.abs(formData.total_amount),
      sent: isSent !== "" ? true : false,
    };
    await updateInvoice({ variables });
    handleClose();
  }

  async function handleSend() {
    const strObjects = JSON.stringify({
      id: formData.id,
      user_id: currentUserId,
      user_name: formData.user.name,
      user_company: formData.user.website,
      user_phone: formData.user.phone_number,
      user_email: formData.user.email,
      user_profile_image: formData.user.profile_image,
      to_company: formData.to_company,
      to_email: formData.to_email,
      to_name: formData.to_name,
      to_phone: formData.to_phone,
      invoice_no: formData.invoice_no,
      invoice_date: formData.invoice_date,
      item: formData.item,
      description: formData.description,
      unit_price: Math.abs(formData.unit_price),
      total_net_price: Math.abs(formData.total_net_price),
      tax_amount: Math.abs(formData.tax_amount),
      total_amount: Math.abs(formData.total_amount),
    });

    const paraForSGMailer = {
      to: formData.to_email,
      from: formData.user.email,
      subject: `your invoice# ${formData.invoice_no} is ready `,
      text: "your invoice is ready",
      html: "html",
      strObjects,
    };

    const { message } = await sendSGEmail(
      paraForSGMailer.to,
      paraForSGMailer.from,
      paraForSGMailer.subject,
      paraForSGMailer.text,
      paraForSGMailer.html,
      paraForSGMailer.strObjects
    );

    // handle Notification
    message === "email sent!" && handleUpdate({ isSent: true });

    message === "email sent!"
      ? StoreNotification({
          title: "invoice",
          message: "Invoice sent successfully & account updated!",
          type: "success",
        })
      : StoreNotification({
          title: "invoice",
          message: "Oops! failed to sent out invoice, try it again...",
          type: "failed",
        });

    handleClose();
  }

  function isAValidKey(key) {
    return (
      [
        "user",
        "to_address",
        "unit_price",
        "total_net_price",
        "count",
        "id",
        "description",
        "tax_amount",
        "__typename",
      ].indexOf(key) < 0
    );
  }

  function displayFilter(key, ar) {
    switch (key) {
      case "invoice_date":
        return formatPostDate(ar[key]);

      case "sent":
        return ar[key] ? (
          <Check
            onClick={() => handleClickEditOpen(ar)}
            color="primary"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <SendRounded
            style={{ opacity: "0.3", cursor: "pointer" }}
            onClick={() => handleClickEditOpen(ar)}
          />
        );

      case "paid":
        return <Payment style={{ opacity: ar[key] ? 1 : "0.3" }} />;
      default:
        return ar[key];
    }
  }

  return (
    <Grid container>
      <Grid item justify="flex-end" sm={12} md={12}>
        <Button onClick={handleClickOpen} color="primary">
          <AddRounded />
          New Invoice
        </Button>
      </Grid>
      <Grid item sm={12} md={12}>
        {loading ? (
          <CircularProgress />
        ) : data && data.invoices.length === 0 ? (
          <Typography variant="body1" color="primary">
            no invoice yet, create one!
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {data &&
                    Object.keys(data.invoices[0]).map((k) => (
                      <>{isAValidKey(k) && <TableCell>{k}</TableCell>}</>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.invoices.map((item, idx) => (
                    <TableRow key={idx}>
                      {Object.keys(data.invoices[0]).map((k) => (
                        <>
                          {isAValidKey(k) && (
                            <TableCell>{displayFilter(k, item)}</TableCell>
                          )}
                        </>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      {/* 1. popup for New Invoice Generation */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Invoice</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6} justify="flex-start">
              <Typography variant="h6">{me.name}</Typography>
              <Typography variant="h6">{me.email}</Typography>
            </Grid>
            <Grid item sm={12} md={6} justify="flex-end">
              <Typography variant="h2">INVOICE</Typography>
            </Grid>
            <Grid item sm={12} md={6} justify="flex-start">
              <TextField
                margin="dense"
                name="to_name"
                value={formData.to_name}
                onChange={handleChange}
                label="Name"
                fullWidth
              />
              <TextField
                margin="dense"
                name="to_company"
                value={formData.to_company}
                onChange={handleChange}
                label="Company"
                fullWidth
              />
              <TextField
                margin="dense"
                name="to_address"
                value={formData.to_address}
                onChange={handleChange}
                label="Address"
                fullWidth
              />
              <TextField
                margin="dense"
                name="to_email"
                value={formData.to_email}
                onChange={handleChange}
                label="Email"
                fullWidth
              />

              <TextField
                margin="dense"
                name="to_phone"
                value={formData.to_phone}
                onChange={handleChange}
                label="Phone"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6} justify="flex-end">
              <Typography variables="h6">
                Date: {formatPostDate(new Date())}
              </Typography>
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                autoFocus
                margin="dense"
                name="item"
                value={formData.item}
                onChange={handleChange}
                id="item"
                label="Item"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                label="Description"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                autoFocus
                margin="dense"
                id="price"
                name="unit_price"
                value={formData.unit_price}
                onChange={handleChange}
                label="Unit Price"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <TextField
                autoFocus
                margin="dense"
                id="total_net_price"
                name="total_net_price"
                value={formData.total_net_price}
                onChange={handleChange}
                label="Total Net Price"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6} justify="flex-end"></Grid>
            <Grid item sm={12} md={6} justify="flex-end">
              <TextField
                autoFocus
                margin="dense"
                id="tax"
                name="tax_amount"
                value={formData.tax_amount}
                onChange={handleChange}
                label="Tax"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6} justify="flex-end"></Grid>
            <Grid item sm={12} md={6} justify="flex-end">
              <TextField
                autoFocus
                margin="dense"
                id="total_amount"
                name="total_amount"
                value={formData.total_amount}
                onChange={handleChange}
                label="Total Amount"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center">
            <Typography variables="h3">
              THANK YOU FOR YOUR BUSINESS!{" "}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {" "}
            <Cancel /> Cancel
          </Button>
          <Button onClick={handleSave} color="secondary">
            {" "}
            <Save /> Save It
          </Button>
        </DialogActions>
      </Dialog>

      {/* 2. popup for edit and send invoice */}
      <Dialog
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Invoice</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            <Cancel /> Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            disabled={formData.sent && true}
          >
            <Update /> Update
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            disabled={formData.sent && true}
          >
            <Delete /> Delete
          </Button>
          <Button onClick={handleSend} color="primary">
            <Email /> {formData.sent && "Re-"}Send Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
