import React from "react";
import { useDashboardPageStyles } from "../../../styles";
import { Grid, Button, TextField, Typography, NativeSelect } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ADD_MINUTE } from "../../../graphql/mutations";
import { Upload } from "../../shared/Upload";
import handleImageUpload from "../../../utils/handleImageUpload";
import 'react-tabs/style/react-tabs.css';

const inputFormData = {
    amount: '',
    tax: '',
    taxSwitch: true,
    paymethodId: '0cd63886-184b-4cef-98b2-83a001502e3e',
    categoryId: 'df4f5e5b-99df-47e4-a23d-abac6efd04e5',
    attachmentUrl: '',
    description: '',
    fiscal: new Date().getFullYear()
}

export function Expense({ userId, categories, paymethods, loading }) {
    const classes = useDashboardPageStyles();
    const [formData, setFormData] = React.useState(inputFormData)
    const [dropfiles, setDropfiles] = React.useState(undefined)
    const [addMinute] = useMutation(ADD_MINUTE)

    function handleChange(event) {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
            // tax: event.target.name === 'amount' && event.target.value * 0.13
        })
    }

    async function handleExpenses() {
        // attachment_url
        const attachment_url = dropfiles && await handleImageUpload(dropfiles)

        const currState = { ...formData }

        const variables = {
            user_id: userId,
            amount: -(currState.amount),
            tax: -(currState.tax),
            category_id: currState.categoryId,
            paymethod_id: currState.paymethodId,
            description: currState.description,
            attachment_url: attachment_url,
            fiscal: currState.fiscal,
        }

        await addMinute({ variables })
        setFormData(inputFormData)
        setDropfiles(undefined)
    }

    function handleSetDropfile(files) {
        setDropfiles(files[0])
    }

    return (
        <div className={classes.incExpContainer}>
            {loading && <CircularProgress />}
            <Grid container space={5}>
                <Grid item xs={12} md={4}>Net Amount(no tax)</Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        onChange={handleChange}
                        name="amount"
                        focused
                        value={formData.amount}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>Tax</Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        onChange={handleChange}
                        name="tax"
                        value={formData.tax}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Payment Method</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <NativeSelect
                        name="paymethodId"
                        fullWidth
                        defaultValue={formData.paymethodId}
                        onChange={handleChange}>
                        {paymethods && paymethods.map(method => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Category</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <NativeSelect
                        name="categoryId"
                        fullWidth
                        defaultValue={formData.categoryId}
                        inputProps={{
                            name: 'categoryId',
                            id: 'category'
                        }}
                        onChange={handleChange}>
                        {categories && categories.map(cat => (
                            <option value={cat.id}>{cat.category_name}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} md={4}><Typography variant="body1">Description</Typography></Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        id="outlined-multiline-static"
                        name="description"
                        onChange={handleChange}
                        multiline
                        fullWidth
                        rows={3}
                        value={formData.description}
                        placeholder="expense detail..."
                    />
                </Grid>
                <Grid item xs={12} md={4}>Attach Receipt</Grid>
                <Grid item xs={12} md={8}>
                    <div className={classes.dropdown}>
                        <Upload onDrop={files => {
                            handleSetDropfile(files)
                        }} />
                    </div>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={8}>
                    <Button
                        onClick={handleExpenses}
                        variant="contained"
                        fullWidth>SUBMIT EXPENSE</Button>
                </Grid>
            </Grid>
        </div>
    )
}