import { gql } from "apollo-boost";
import { userFields, gridPostFields } from "./fragments";

export const GET_MONTHSUMS_REPORT = gql`
query getTaxReportByMonth(
  $fiscal: Int, 
  $user_id: uuid!, 
  $from10: timestamptz, $to10: timestamptz,
  $from11: timestamptz, $to11: timestamptz,
  $from12: timestamptz, $to12: timestamptz
  
  ) {
  monthIn10: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from10, _lt: $to10}, amount: {_gt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthOut10: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from10, _lt: $to10}, amount: {_lt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthTotal10: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from10, _lt: $to10}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  
  monthIn11: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from11, _lt: $to11}, amount: {_gt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthOut11: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from11, _lt: $to11}, amount: {_lt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthTotal11: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from11, _lt: $to11}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthIn12: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from12, _lt: $to12}, amount: {_gt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthOut12: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from12, _lt: $to12}, amount: {_lt: "0"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  monthTotal12: minutes_aggregate(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_gte: $from12, _lt: $to12}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  minutes(where: {fiscal: {_eq: $fiscal}, user_id: {_eq: $user_id}, 
  created_at: {_lt: $to10, _gte: $from10}}, order_by: {created_at: asc}) {
    id
    created_at
    category {
      category_name
    }
    updated_at
    tax
    amount
  }
  }
`;

export const GET_TAX_REPORT = gql`
  query getTaxReportData($fiscal: Int = 10, $user_id: uuid!) {
    out: minutes_aggregate(
      where: {
        fiscal: { _eq: $fiscal }
        user_id: { _eq: $user_id }
        category_id: { _neq: "8dacb2e2-3b62-4378-8f93-096ea505ad4f" }
        amount: { _lt: 0 }
      }
      order_by: { category: { category_name: asc } }
    ) {
      aggregate {
        sum {
          amount
          tax
        }
      }
      nodes {
        category {
          category_name
        }
        amount
        tax
      }
    }
    in: minutes_aggregate(
      where: {
        fiscal: { _eq: $fiscal }
        user_id: { _eq: $user_id }
        amount: { _gt: 0 }
      }
      order_by: { category: { category_name: asc } }
    ) {
      aggregate {
        sum {
          amount
          tax
        }
      }
      nodes {
        category {
          category_name
        }
        amount
        tax
      }
    }
    payroll: minutes_aggregate(
      where: {
        fiscal: { _eq: $fiscal }
        user_id: { _eq: $user_id }
        category_id: { _eq: "8dacb2e2-3b62-4378-8f93-096ea505ad4f" }
      }
    ) {
      aggregate {
        sum {
          amount
          tax
        }
      }
      nodes {
        category {
          category_name
        }
        amount
        tax
      }
    }
  }
`;

export const GET_USERS_CATEGORIES = gql`
query queryUsersCategories {
  users: users(order_by: {created_at: desc, last_login: desc}) {
    profile_image
    email
    created_at
    bio
    name
    phone_number
    role
    user_id
    username
    website
    last_login
    status
    id
  }
  categories: categories(order_by: {category_name: asc}) {
    adjustment_rate
    category_code
    category_name
    created_at
    display
    id
  }
  invoices(order_by: {invoice_date: desc}) {
    description
    invoice_date
    item
    sent
    tax_amount
    to_company
    to_email
    to_name
    to_phone
    total_amount
    user {
      name
    }
  }
}


`;

export const GET_FISCAL_PAYMETHODS_CATEGORIES = gql`
  query getMyFiscalsPaymethodsCategories($user_id: uuid!) {
    fiscals: minutes_aggregate(
      distinct_on: fiscal
      where: { user_id: { _eq: $user_id } }
      order_by: { fiscal: desc }
    ) {
      nodes {
        fiscal
      }
    }
    paymethods: minutes_aggregate(
      distinct_on: paymethod_id
      where: { user_id: { _eq: $user_id } }
    ) {
      nodes {
        method {
          name
          id
        }
      }
    }
    categories: minutes_aggregate(
      distinct_on: category_id
      where: { user_id: { _eq: $user_id } }
    ) {
      nodes {
        category {
          category_name
          id
        }
      }
    }
  }
`;

export const GET_PAYMETHODS_CATEGORIES = gql`
  query getPaymethodsCategories {
    paymethods {
      id
      description
      name
      type
    }
    categories(
      where: { display: { _eq: true } }
      order_by: { category_name: asc }
    ) {
      id
      category_name
      category_code
    }
  }
`;

export const GET_PAYMETHODS_CATEGORIES_2 = gql`
  query getPaymethodsCategories($user_id: uuid) {
    paymethods {
      id
      description
      name
      type
    }
    agg_cats: minutes_aggregate(
      where: { user_id: { _eq: $user_id } }
      distinct_on: category_id
    ) {
      nodes {
        category {
          category_name
          id
        }
      }
      aggregate {
        count(columns: category_id)
      }
    }
    list_cats: categories(
      where: { display: { _eq: true } }
      order_by: { category_name: asc }
    ) {
      id
      category_name
      category_code
      minutes(order_by: { category: { category_name: asc } }) {
        user_id
      }
    }
  }
`;

export const VALIDATE_PROBLEM_WITH_STUDENT_EMAIL = gql`
  query validateStudentEmail($problemId: uuid!, $studentEmail: String) {
    dm_questions(
      where: {
        _and: { studentEmail: { _eq: $studentEmail }, id: { _eq: $problemId } }
      }
    ) {
      studentEmail
    }
  }
`;

export const GET_THE_QUESTION = gql`
  query getTheQuestion($id: uuid!) {
    dm_questions_by_pk(id: $id) {
      category
      createdAt
      days
      id
      instructorId
      problem
      problemFile
      status
      studentEmail
      studentId
    }
  }
`;

export const GET_ALL_QUESTIONS = gql`
  query getAllQuestions {
    dm_questions(limit: 10) {
      id
      instructorId
      status
      studentEmail
      studentId
      problem
      createdAt
      days
      category
      problemFile
    }
  }
`;

export const CHECK_IF_USERNAME_TAKEN = gql`
  query checkIfUsernameTaken($username: String!) {
    users(where: { username: { _eq: $username } }) {
      username
    }
  }
`;

export const GET_USER_EMAIL = gql`
  query getUserEmail($input: String!) {
    users(
      where: {
        _or: [{ username: { _eq: $input } }, { phone_number: { _eq: $input } }]
      }
    ) {
      email
    }
  }
`;

export const GET_EDIT_USER_PROFILE = gql`
  query getEditUserProfile($id: uuid!) {
    users_by_pk(id: $id) {
      id
      username
      name
      email
      bio
      profile_image
      website
      phone_number
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($query: String) {
    users(
      where: {
        _or: [{ username: { _ilike: $query } }, { name: { _ilike: $query } }]
      }
    ) {
      ...userFields
    }
  }
  ${userFields}
`;

export const GET_INSTRUCTOR_TASK_LIST = gql`
  query getInstructorTaskList($instructorId: uuid!) {
    dm_fixes(where: { instructorId: { _eq: $instructorId } }) {
      createdAt
      id
      instructorId
      post
      problemId
      solutionFile
      status
      userId
    }
  }
`;

export const GET_USER_TASK_LIST = gql`
  query getUserTaskList($userId: uuid!) {
    dm_fixes(where: { userId: { _eq: $userId } }) {
      createdAt
      id
      instructorId
      post
      problemId
      solutionFile
      status
      userId
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($username: String!) {
    users(where: { username: { _eq: $username } }) {
      id
      name
      username
      website
      bio
      profile_image
    }
  }
`;

// suggest users from followers and also users created around the same time
export const SUGGEST_USERS = gql`
  query suggestUsers(
    $limit: Int!
    $followerIds: [uuid!]!
    $createdAt: timestamptz!
  ) {
    users(
      limit: $limit
      where: {
        _or: [
          { id: { _in: $followerIds } }
          { created_at: { _gt: $createdAt } }
        ]
      }
    ) {
      ...userFields
    }
  }
  ${userFields}
`;

// posts with the most likes and comments at the top, newest to oldest where the posts are not from users we are following
export const EXPLORE_POSTS = gql`
  query explorePosts($feedIds: [uuid!]!) {
    posts(
      order_by: {
        created_at: desc
        likes_aggregate: { count: desc }
        comments_aggregate: { count: desc }
      }
      where: { user_id: { _nin: $feedIds } }
    ) {
      ...gridPostFields
    }
  }
  ${gridPostFields}
`;

export const GET_MORE_POSTS_FROM_USER = gql`
  query getMorePostsFromUser($userId: uuid!, $postId: uuid!) {
    posts(
      limit: 6
      where: { user_id: { _eq: $userId }, _not: { id: { _eq: $postId } } }
    ) {
      ...gridPostFields
    }
  }
  ${gridPostFields}
`;

export const GET_POST = gql`
  query getPost($postId: uuid!) {
    posts_by_pk(id: $postId) {
      id
      user {
        id
        username
      }
    }
  }
`;

export const GET_FEED = gql`
  query getFeed($limit: Int!, $feedIds: [uuid!]!, $lastTimestamp: timestamptz) {
    posts(
      limit: $limit
      where: { user_id: { _in: $feedIds }, created_at: { _lt: $lastTimestamp } }
      order_by: { created_at: desc }
    ) {
      id
      caption
      created_at
      media
      location
      user {
        id
        username
        name
        profile_image
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      likes {
        id
        user_id
      }
      saved_posts {
        id
        user_id
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
      comments(order_by: { created_at: desc }, limit: 2) {
        id
        content
        created_at
        user {
          username
        }
      }
    }
  }
`;
