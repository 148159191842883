import React from "react";
import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import ProfilePage from "./pages/profile";
import EditProfilePage from "./pages/edit-profile";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/signup";
import NotFoundPage from "./pages/not-found";
import { AuthContext } from "./auth";
import { useSubscription } from "@apollo/react-hooks";
import { ME } from "./graphql/subscriptions";
import LoadingScreen from "./components/shared/LoadingScreen";
import DashboardPage from "./pages/dashboard";
import PricePage from "./pages/price";
import AdminPage from "./pages/admin";
import ReportPage from "./pages/report";
import InvoicePage from "./pages/invoice";
import TaxPage from "./pages/tax";

export const UserContext = React.createContext();

function App() {
  const { authState } = React.useContext(AuthContext);
  const isAuth = authState.status === "in";
  const userId = isAuth ? authState.user.uid : null;
  const variables = { userId };
  const { data, loading } = useSubscription(ME, { variables });
  const history = useHistory();
  const location = useLocation();
  const prevLocation = React.useRef(location);
  const modal = location.state?.modal;

  React.useEffect(() => {
    if (history.action !== "POP" && !modal) {
      prevLocation.current = location;
    }
  }, [location, modal, history.action]);

  if (loading) return <LoadingScreen />;

  if (!isAuth) {
    return (
      <Switch>
        <Route exact path="/accounts/login" component={LoginPage} />
        <Route exact path="/accounts/emailsignup" component={SignUpPage} />
        <Route exact path="/" component={DashboardPage} />
        <Redirect to="/pages/dashboard" />
      </Switch>
    );
  } else {

    const isModalOpen = modal && prevLocation.current !== location;
    const me = isAuth && data ? data.users[0] : null;
    const currentUserId = me.id;

    return (
      <>
          <UserContext.Provider value={{ me, currentUserId }}>
            <Switch location={isModalOpen ? prevLocation.current : location}>
              <Route exact path="/" component={DashboardPage} />
              <Route exact path="/price" component={PricePage} />
              <Route exact path="/report" component={ReportPage} />
              <Route exact path="/invoice" component={InvoicePage} />
              <Route exact path="/tax" component={TaxPage} />
              <Route exact path="/admin" component={AdminPage} />
              <Route exact path="/:username" component={ProfilePage} />
              <Route exact path="/accounts/edit" component={EditProfilePage} />
              <Route exact path="/accounts/login" component={LoginPage} />
              <Route exact path="/accounts/emailsignup" component={SignUpPage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </UserContext.Provider>
      </>
    );
  }
}

export default App;
