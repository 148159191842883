import React from "react";
import { useNavbarStyles, WhiteTooltip } from "../../styles";
import { AppBar, Hidden, InputBase, Avatar, Fade, Grid, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/logo.png";
import { LoadingIcon } from "../../icons";
import NotificationList from "../notification/NotificationList";
import { useNProgress } from "@tanem/react-nprogress";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import { SEARCH_USERS } from "../../graphql/queries";
import { UserContext } from "../../App";
import { AuthContext } from "../../auth";
import { ME } from "../../graphql/subscriptions";
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
// import { GrUserAdmin } from 'react-icons/gr'
import useMatchMedia from "./useMatchMedia";

function Navbar({ minimalNavbar }) {
  const classes = useNavbarStyles();
  const history = useHistory();
  const [isLoadingPage, setLoadingPage] = React.useState(true);
  const path = history.location.pathname;
  const { authState } = React.useContext(AuthContext);
  const isAuth = authState.status === 'in';
  const userId = isAuth ? authState.user.uid : null;
  const variables = { userId };
  const { data } = useSubscription(ME, { variables });
  const me = isAuth && data ? data.users[0] : null;
  const currentUserRole = isAuth && data && me.role;

  React.useEffect(() => {
    setLoadingPage(false);
  }, [path]);

  return (
    <>
      <Progress isAnimating={isLoadingPage} />
      <AppBar className={classes.appBar}>
        <section className={classes.section}>
          <Typography variant="h5" color="primary">
            <Link to="/">
              <Logo />
            </Link>
          </Typography>

          {!minimalNavbar && isAuth ? (
            <Links path={path} role={currentUserRole} />
          ) : (
              <div className={classes.linksContainer}>
                <Typography variant="body1" color="primary">
                  <Link to="/accounts/login"><AssignmentReturnIcon />{" "}Sign In</Link>
                </Typography>
              </div>
            )}
        </section>
      </AppBar>
    </>
  );
}

function Logo() {
  const classes = useNavbarStyles();

  return (
    <div className={classes.logoContainer}>
      <Link to="/">
        <div className={classes.logoWrapper}>
          <img src={logo} alt="" className={classes.logo} />
        </div>
      </Link>
    </div>
  );
}

function Search({ history }) {
  const classes = useNavbarStyles();
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [searchUsers, { data }] = useLazyQuery(SEARCH_USERS);

  const hasResults = Boolean(query) && results.length > 0;

  React.useEffect(() => {
    if (!query.trim()) return;
    setLoading(true);
    const variables = { query: `%${query}%` };
    searchUsers({ variables });
    if (data) {
      setResults(data.users);
      setLoading(false);
    }
    // setResults(Array.from({ length: 5 }, () => getDefaultUser()));
  }, [query, data, searchUsers]);

  function handleClearInput() {
    setQuery("");
  }

  return (
    <Hidden xsDown>
      <WhiteTooltip
        arrow
        interactive
        TransitionComponent={Fade}
        open={hasResults}
        title={
          hasResults && (
            <Grid className={classes.resultContainer} container>
              {results.map((result) => (
                <Grid
                  key={result.id}
                  item
                  className={classes.resultLink}
                  onClick={() => {
                    history.push(`/${result.username}`);
                    handleClearInput();
                  }}
                >
                  <div className={classes.resultWrapper}>
                    <div className={classes.avatarWrapper}>
                      <Avatar
                        src={result.profile_image} alt="user avatar" />
                    </div>
                    <div className={classes.nameWrapper}>
                      <Typography variant="body1">{result.username}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {result.name}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )
        }
      >
        <InputBase
          className={classes.input}
          onChange={(event) => setQuery(event.target.value)}
          startAdornment={<span className={classes.searchIcon} />}
          endAdornment={
            loading ? (
              <LoadingIcon />
            ) : (
                <span onClick={handleClearInput} className={classes.clearIcon} />
              )
          }
          placeholder="Search"
          value={query}
        />
      </WhiteTooltip>
    </Hidden>
  );
}

function Links({ path, role }) {
  const { me, currentUserId } = React.useContext(UserContext);

  const classes = useNavbarStyles();
  const [showList, setList] = React.useState(false);


  const isMobile = useMatchMedia()

  function handleHideList() {
    setList(false);
  }

  return (
    <div className={classes.linksContainer}>
      {me && showList && (
        <NotificationList
          notifications={me.notifications}
          handleHideList={handleHideList}
          currentUserId={currentUserId}
        />
      )}
      <Search />
      <div className={classes.linksWrapper}>
        <Link to="/"> {!isMobile && "Transaction"} </Link>
        <Link to="/invoice">  {!isMobile && "Invoice"} </Link>
        <Link to="/tax">  {!isMobile && "Tax" }</Link>
        {/* <Link to="/report" > <AssessmentRounded /> Report </Link> */}
        {/* <Link to="/payroll"> <Payment /> Payroll </Link> */}
        {/* <Link to="/price"> <LocalOffer /> Price </Link> */}
        {role && role === 'admin' &&
          <Link to="/admin" alt="administration"> {!isMobile && "Admin"}</Link>
        }
        <Link to={`/${me.username}`}>
              <Avatar src={me.profile_image} className={classes.profileImage} /> 
        </Link>
      </div>
    </div>
  );
}

function Progress({ isAnimating }) {
  const classes = useNavbarStyles();
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <div
      className={classes.progressContainer}
      style={{
        opacity: isFinished ? 0 : 1,
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      <div
        className={classes.progressBar}
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
      >
        <div className={classes.progressBackground} />
      </div>
    </div>
  );
}

export default Navbar;
