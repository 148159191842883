import React from "react";
import { useDashboardPageStyles } from "../../../styles";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useQuery } from "@apollo/react-hooks";
import { GET_PAYMETHODS_CATEGORIES } from "../../../graphql/queries";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Expense } from "./InputExpense"
import { Income } from "./InputIncome"

export default function InputForm({ userId }) {
    const classes = useDashboardPageStyles();
    const { data, loading } = useQuery(GET_PAYMETHODS_CATEGORIES)

    return (
        <div className={classes.innerContainer}>
            <Tabs>
                <TabList>
                    <Tab>Expense</Tab>
                    <Tab>Income</Tab>
                </TabList>
                <TabPanel>
                    {!data ? <CircularProgress /> : (
                        <Expense
                            userId={userId}
                            categories={data.categories}
                            paymethods={data.paymethods}
                            loading={loading} />
                    )}
                </TabPanel>
                <TabPanel>
                    {!data ? <CircularProgress /> : (
                        <Income
                            userId={userId}
                            categories={data.categories}
                            paymethods={data.paymethods}
                            loading={loading} />
                    )}
                </TabPanel>
            </Tabs>
        </div>
    )
}