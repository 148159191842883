import React from 'react'
import { Button, CircularProgress, NativeSelect, TextField } from "@material-ui/core";
import { useComponentToolbarStyle } from '../../styles';
import { useQuery } from '@apollo/react-hooks';
import { Search } from '@material-ui/icons';
import { GET_FISCAL_PAYMETHODS_CATEGORIES } from '../../graphql/queries';
import useMatchMedia from '../shared/useMatchMedia';

export default function ToolBar({ userId, handleChange, handleSearch, searchState }) {
    const classes = useComponentToolbarStyle()
    const variables = {
        user_id: userId
    }
    const { data, loading } = useQuery(GET_FISCAL_PAYMETHODS_CATEGORIES, { variables })
    const isMobile = useMatchMedia()

    return (
        <div className={classes.toolbarContainer}>
            <div className={classes.toolbarSearch}>
                <TextField
                    name="keyword"
                    value={searchState.keyword}
                    style={{ width: isMobile ? 100 : 250 }}
                    placeholder="description search..." />
                <Button onClick={handleSearch}><Search /></Button>
            </div>
            <div className={classes.toolbarDropdownSearch}>
                {loading && <CircularProgress />}
                <NativeSelect
                    onChange={handleChange}
                    name="paymethodId"
                    variant="outline"
                    defaultValue={searchState.paymethodsId}
                    style={{ display: !isMobile ? true : 'none'}}
                >
                    <option>All Pay Method</option>
                    {data && data.paymethods.nodes.map(m => (
                        <option value={m.method.id}>{m.method.name}</option>
                    ))}
                </NativeSelect>
                <NativeSelect
                    onChange={handleChange}
                    name="categoryId"
                    defaultValue={searchState.categoryId}   
                    variant="outline"
                    style={{ display: !isMobile ? true : 'none'}}
                >
                    <option>All Category</option>
                    {data && data.categories.nodes.map(m => (
                        <option value={m.category.id}>{m.category.category_name}</option>
                    ))}
                </NativeSelect>
                <NativeSelect
                    onChange={handleChange}
                    name="month"
                >
                        <option>All Month</option>
                        {[{n:'Jan',v:1},{n:'Feb',v:2},{n:'Mar',v:3},
                        {n:'Apr',v:4},
                        {n:'May',v:5},
                        {n:'Jun',v:6},
                        {n:'Jul',v:7},
                        {n:'Aug',v:8},
                        {n:'Sept',v:9},
                        {n:'Oct',v:10},
                        {n:'Nov',v:11},
                        {n:'Dec',v:12},
                    ].map(m=>(
                            <option value={m.v}>{m.n}</option>
                        ))}
                </NativeSelect>
                <NativeSelect
                    onChange={handleChange}
                    name="fiscal"
                    variant="outlined"
                    defaultValue={searchState.fiscal}
                >
                    {data && data.fiscals.nodes.map(m => (
                        <option value={m.fiscal}>{m.fiscal}</option>
                    ))}
                </NativeSelect>
            </div>
        </div>
    )
}