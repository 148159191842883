import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDashboardPageStyles } from "../../../styles";
import {
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { formatPostDate } from "../../../utils/formatDate";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Draggable from "react-draggable";
import { MiniuteEditing } from "./MiniuteEditing";
import {
  Delete,
  Cancel,
  EditLocationOutlined,
  Update,
  Image,
} from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_MINUTE, UPDATE_MINUTE, UPDATE_MINUTE_WITHOUT_ATTACHEMTN } from "../../../graphql/mutations";
import handleImageUpload from "../../../utils/handleImageUpload";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useMatchMedia from "../../shared/useMatchMedia";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ExpIncList({ userId, loading, data }) {
  const classes = useDashboardPageStyles();
  const [attachmentUrl, setAttachmentUrl] = React.useState();
  const [open, setOpen] = React.useState();
  const [openEditor, setOpenEditor] = React.useState();
  const [selectedMinute, setSelectedMinute] = React.useState();
  const [updateMinute] = useMutation(UPDATE_MINUTE);
  const [updateMinuteWithoutAttachment] = useMutation(UPDATE_MINUTE_WITHOUT_ATTACHEMTN);
  const [deleteMinutes] = useMutation(DELETE_MINUTE);
  const [dropfiles, setDropfiles] = React.useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMatchMedia();

  const handleClickOpen = (url) => {
    setAttachmentUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditorOpen = (minute) => {
    setSelectedMinute(minute);
    setOpenEditor(true);
  };

  const handleEditorClose = () => {
    setOpenEditor(false);
  };

  function handleChange(e) {
    e.preventDefault();
    setSelectedMinute({
      ...selectedMinute,
      [e.target.name]: e.target.value,
    });
  }
  async function handleUpdate() {
    const attachmentUrl = dropfiles && (await handleImageUpload(dropfiles));
    const variables = {
      user_id: userId,
      id: selectedMinute.id,
      amount: selectedMinute.amount,
      tax: selectedMinute.tax,
      category_id: selectedMinute.categoryId
        ? selectedMinute.categoryId
        : selectedMinute.category.id,
      paymethod_id: selectedMinute.paymethodId
        ? selectedMinute.paymethodId
        : selectedMinute.method.id,
      // attachment_url: attachmentUrl,
      description: selectedMinute.description,
      fiscal: selectedMinute.fiscal,
      updated_at: new Date(),
    };

    if (dropfiles) {
      variables["attachment_url"] = attachmentUrl;
      await updateMinute({ variables });
    } else {
        await updateMinuteWithoutAttachment({ variables})
    }

    // close popup
    handleEditorClose();
  }

  async function handleDelete() {
    //todo delete
    const variables = {
      id: selectedMinute.id,
    };
    await deleteMinutes({ variables });
    // close popup
    handleEditorClose();
  }

  return (
    <div className={classes.ExpIncList}>
      {/* listings */}
      <TableContainer component={Paper}>
        {loading && <CircularProgress />}
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={
                  isMobile ? classes.hideTableCell : classes.showTableCell
                }
              ></TableCell>
              <TableCell>{isMobile ? "CREATED" : "CREATED AT"}</TableCell>
              <TableCell align="right">AMOUNT</TableCell>
              <TableCell align="right">TAX</TableCell>
              <TableCell
                className={
                  isMobile ? classes.hideTableCell : classes.showTableCell
                }
              >
                DESCRIPTION
              </TableCell>
              <TableCell
                className={
                  isMobile ? classes.hideTableCell : classes.showTableCell
                }
              >
                CATEGORY
              </TableCell>
              <TableCell
                className={
                  isMobile ? classes.hideTableCell : classes.showTableCell
                }
              >
                PAYMENT
              </TableCell>
              <TableCell>{isMobile ? "" : "ATTACHMENT"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.minutes.map((row, idx) => (
                <TableRow key={idx} className={classes.tableRowHover}>
                  <TableCell
                    className={
                      isMobile ? classes.hideTableCell : classes.showTableCell
                    }
                    onClick={() => handleEditorOpen(row)}
                  >
                    <EditLocationOutlined fontSize="small" />
                  </TableCell>
                  <TableCell onClick={() => handleEditorOpen(row)}>
                    {formatPostDate(row.created_at)}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => handleEditorOpen(row)}
                  >
                    {
                      <span className={row.amount > 0 && classes.incomeLine}>
                        {row.amount}
                      </span>
                    }
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => handleEditorOpen(row)}
                  >
                    {
                      <span className={row.tax > 0 && classes.incomeLine}>
                        {row.tax}
                      </span>
                    }
                  </TableCell>
                  <TableCell
                    className={
                      isMobile ? classes.hideTableCell : classes.showTableCell
                    }
                    onClick={() => handleEditorOpen(row)}
                  >
                    {row.description}
                  </TableCell>

                  <TableCell
                    className={
                      isMobile ? classes.hideTableCell : classes.showTableCell
                    }
                    onClick={() => handleEditorOpen(row)}
                  >
                    {row.category.category_name}
                  </TableCell>
                  <TableCell
                    className={
                      isMobile ? classes.hideTableCell : classes.showTableCell
                    }
                    onClick={() => handleEditorOpen(row)}
                  >
                    {row.method.name}
                  </TableCell>
                  <TableCell>
                    {row.attachment_url ? (
                      <ImageIcon
                        className={classes.imageIcon}
                        size="small"
                        onClick={() => handleClickOpen(row.attachment_url)}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* dialog show attachment */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <Image src={attachmentUrl} className={classes.popImage} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog editing */}
      <Dialog
        open={openEditor}
        onClose={handleEditorClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" color="primary">
            Edit
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedMinute && (
              <MiniuteEditing
                setDropfiles={setDropfiles}
                handleChange={handleChange}
                minutes={selectedMinute}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12} md={8} justify="flex-start">
              <Button autoFocus onClick={handleDelete} color="danger">
                <Delete /> Delete
              </Button>
            </Grid>
            <Grid item xs={12} md={4} justify="flex-end">
              <Button autoFocus onClick={handleEditorClose} color="primary">
                <Cancel /> Cancel{" "}
              </Button>
              <Button onClick={handleUpdate} color="secondary">
                <Update /> Update{" "}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
