import axios from 'axios'

async function sendSGEmail(
  to,
  from,
  subject,
  text,
  html,
  strObjects) {
  const sgMailer = process.env.REACT_APP_SG_MAILER
  const sgMessageObj = {
    to, from, subject, text, html, strObjects
  }

  const { data } = await axios.post(sgMailer, sgMessageObj)
  return data  //{message: sent!}
}

export default sendSGEmail