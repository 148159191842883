import { gql } from "apollo-boost";

export const UPDATE_INVOICE = gql`
  mutation updateInvoice(
    $user_id: uuid!
    $id: uuid!
    $to_address: String
    $to_company: String
    $to_email: String!
    $to_name: String
    $to_phone: String
    $item: String
    $description: String
    $unit_price: numeric
    $total_net_price: numeric
    $tax_amount: numeric
    $total_amount: numeric
    $sent: Boolean
    ) {
    update_invoices(
      where: { 
        id: { _eq: $id }, 
        user_id: { _eq: $user_id } 
      }
      _set: {
        to_address: $to_address 
        to_company: $to_company
        to_email: $to_email
        to_name: $to_name
        to_phone: $to_phone
        item: $item
        description: $description 
        unit_price: $unit_price
        total_net_price: $total_net_price
        tax_amount: $tax_amount
        total_amount: $total_amount
        sent: $sent
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation deleteInvoice(
    $user_id: uuid!
    $id: uuid!
    ) {
    delete_invoices(
      where: { 
        id: { _eq: $id }, 
        user_id: { _eq: $user_id } 
      }) {
      affected_rows
    }
  }
`;

export const ADD_INVOICE = gql`
  mutation addNewInvoice(
    $invoice_no: String
    $to_address: String
    $to_company: String
    $to_email: String!
    $to_name: String
    $to_phone: String
    $item: String
    $description: String
    $sent: Boolean
    $unit_price: numeric!
    $total_net_price: numeric!
    $tax_amount: numeric!
    $total_amount: numeric!
    $user_id: uuid!
  ) {
    insert_invoices(
      objects: {
        invoice_no: $invoice_no
        to_address: $to_address
        to_company: $to_company
        to_email: $to_email
        to_name: $to_name
        to_phone: $to_phone
        item: $item
        description: $description
        unit_price: $unit_price
        total_net_price: $total_net_price
        tax_amount: $tax_amount
        total_amount: $total_amount
        user_id: $user_id
        sent: $sent
      }
    ) {
      affected_rows
    }
  }
`;

export const ADMIN_QUERY_USER = gql`
  query adminQueryUsers {
    users {
      email
      created_at
      bio
      name
      phone_number
      role
      user_id
      username
      website
    }
  }
`;

export const DELETE_MINUTE = gql`
  mutation deleteMinutes($id: uuid!) {
    delete_minutes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_MINUTE = gql`
  mutation updateMinute(
    $user_id: uuid!
    $id: uuid!
    $amount: numeric
    $attachment_url: String
    $category_id: uuid
    $description: String
    $fiscal: Int
    $paymethod_id: uuid
    $tax: numeric
    $updated_at: timestamptz
  ) {
    update_minutes(
      where: { _and: { id: { _eq: $id }, user_id: { _eq: $user_id } } }
      _set: {
        amount: $amount
        attachment_url: $attachment_url
        category_id: $category_id
        paymethod_id: $paymethod_id
        fiscal: $fiscal
        description: $description
        tax: $tax
        updated_at: $updated_at
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_MINUTE_WITHOUT_ATTACHEMTN = gql`
  mutation updateMinuteWithoutAttachment(
    $user_id: uuid!
    $id: uuid!
    $amount: numeric
    $category_id: uuid
    $description: String
    $fiscal: Int
    $paymethod_id: uuid
    $tax: numeric
    $updated_at: timestamptz
  ) {
    update_minutes(
      where: { _and: { id: { _eq: $id }, user_id: { _eq: $user_id } } }
      _set: {
        amount: $amount
        category_id: $category_id
        paymethod_id: $paymethod_id
        fiscal: $fiscal
        description: $description
        tax: $tax
        updated_at: $updated_at
      }
    ) {
      affected_rows
    }
  }
`;


export const ADD_MINUTE = gql`
  mutation addMinute(
    $user_id: uuid!
    $amount: numeric!
    $tax: numeric!
    $category_id: uuid!
    $paymethod_id: uuid!
    $description: String
    $attachment_url: String
    $fiscal: Int!
  ) {
    insert_minutes(
      objects: {
        user_id: $user_id
        amount: $amount
        category_id: $category_id
        tax: $tax
        paymethod_id: $paymethod_id
        description: $description
        attachment_url: $attachment_url
        fiscal: $fiscal
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_SOLUTION_UPDATE_QUESTION = gql`
  mutation addSolution_updateQuestion(
    $problemId: uuid!
    $post: String!
    $instructorId: uuid
    $userId: uuid
    $solutionFile: String
  ) {
    insert_dm_fixes(
      objects: {
        instructorId: $instructorId
        problemId: $problemId
        post: $post
        solutionFile: $solutionFile
        userId: $userId
      }
    ) {
      affected_rows
    }
    update_dm_questions(
      where: { id: { _eq: $problemId } }
      _set: { instructorId: $instructorId, status: "engaged" }
    ) {
      affected_rows
    }
  }
`;

export const ADD_SOLUTION_SHEET = gql`
  mutation addSolutionSheet(
    $problemId: uuid!
    $post: String!
    $instructorId: uuid
    $userId: uuid
    $solutionFile: String
  ) {
    insert_dm_fixes(
      objects: {
        instructorId: $instructorId
        problemId: $problemId
        post: $post
        solutionFile: $solutionFile
        userId: $userId
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_PROBLEM_SHEET = gql`
  mutation addProblemSheet(
    $problem: String!
    $studentEmail: String
    $days: String
    $category: String
    $problemFile: String
  ) {
    insert_dm_questions(
      objects: {
        problem: $problem
        studentEmail: $studentEmail
        days: $days
        category: $category
        problemFile: $problemFile
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUsers(
    $userId: String!
    $name: String!
    $username: String!
    $email: String!
    $bio: String!
    $website: String!
    $profileImage: String!
    $phoneNumber: String!
  ) {
    insert_users(
      objects: {
        user_id: $userId
        username: $username
        website: $website
        profile_image: $profileImage
        phone_number: $phoneNumber
        name: $name
        email: $email
        bio: $bio
      }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser(
    $id: uuid!
    $name: String!
    $username: String!
    $website: String!
    $bio: String!
    $email: String!
    $phoneNumber: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        username: $username
        website: $website
        bio: $bio
        email: $email
        phone_number: $phoneNumber
      }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_USER_AVATAR = gql`
  mutation editUserAvatar($id: uuid!, $profileImage: String!) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { profile_image: $profileImage }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost(
    $userId: uuid!
    $media: String!
    $location: String!
    $caption: String!
  ) {
    insert_posts(
      objects: {
        user_id: $userId
        media: $media
        location: $location
        caption: $caption
      }
    ) {
      affected_rows
    }
  }
`;

export const LIKE_POST = gql`
  mutation likePost($postId: uuid!, $userId: uuid!, $profileId: uuid!) {
    insert_likes(objects: { post_id: $postId, user_id: $userId }) {
      affected_rows
    }
    insert_notifications(
      objects: {
        post_id: $postId
        user_id: $userId
        profile_id: $profileId
        type: "like"
      }
    ) {
      affected_rows
    }
  }
`;

export const UNLIKE_POST = gql`
  mutation unlikePost($postId: uuid!, $userId: uuid!, $profileId: uuid!) {
    delete_likes(
      where: { 
        post_id: { _eq: $postId }, 
        user_id: { _eq: $userId } 
      }
    ) {
      affected_rows
    }
    delete_notifications(
      where: {
        post_id: { _eq: $postId }
        profile_id: { _eq: $profileId }
        user_id: { _eq: $userId }
        type: { _eq: "like" }
      }
    ) {
      affected_rows
    }
  }
`;

export const SAVE_POST = gql`
  mutation savePost($postId: uuid!, $userId: uuid!) {
    insert_saved_posts(objects: { post_id: $postId, user_id: $userId }) {
      affected_rows
    }
  }
`;

export const UNSAVE_POST = gql`
  mutation unsavePost($postId: uuid!, $userId: uuid!) {
    delete_saved_posts(
      where: { post_id: { _eq: $postId }, user_id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($postId: uuid!, $userId: uuid!, $content: String!) {
    insert_comments(
      objects: { post_id: $postId, user_id: $userId, content: $content }
    ) {
      affected_rows
    }
  }
`;

export const CHECK_NOTIFICATIONS = gql`
  mutation checkNotifications($userId: uuid!, $lastChecked: String!) {
    update_users(
      where: { id: { _eq: $userId } }
      _set: { last_checked: $lastChecked }
    ) {
      affected_rows
    }
  }
`;

export const FOLLOW_USER = gql`
  mutation followUser($userIdToFollow: uuid!, $currentUserId: uuid!) {
    insert_followers(
      objects: { user_id: $userIdToFollow, profile_id: $currentUserId }
    ) {
      affected_rows
    }
    insert_following(
      objects: { user_id: $currentUserId, profile_id: $userIdToFollow }
    ) {
      affected_rows
    }
    insert_notifications(
      objects: {
        user_id: $currentUserId
        profile_id: $userIdToFollow
        type: "follow"
      }
    ) {
      affected_rows
    }
  }
`;

export const UNFOLLOW_USER = gql`
  mutation followUser($userIdToFollow: uuid!, $currentUserId: uuid!) {
    delete_followers(
      where: {
        user_id: { _eq: $userIdToFollow }
        profile_id: { _eq: $currentUserId }
      }
    ) {
      affected_rows
    }
    delete_following(
      where: {
        user_id: { _eq: $currentUserId }
        profile_id: { _eq: $userIdToFollow }
      }
    ) {
      affected_rows
    }
    delete_notifications(
      where: {
        user_id: { _eq: $currentUserId }
        profile_id: { _eq: $userIdToFollow }
        type: { _eq: "follow" }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($postId: uuid!, $userId: uuid!) {
    delete_posts(where: { id: { _eq: $postId }, user_id: { _eq: $userId } }) {
      affected_rows
    }
    delete_likes(where: { post_id: { _eq: $postId } }) {
      affected_rows
    }
    delete_saved_posts(where: { post_id: { _eq: $postId } }) {
      affected_rows
    }
    delete_notifications(where: { post_id: { _eq: $postId } }) {
      affected_rows
    }
  }
`;
