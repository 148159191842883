import React from "react";
import { Avatar, Chip, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useDashboardPageStyles } from "../../styles";
import Pie from "../../components/shared/Pie";
import NumberFormat from "react-number-format";
import useMatchMedia from "../shared/useMatchMedia";

function BalanceTotal({ amountData }) {
    const balance = amountData.length > 0 && amountData.reduce((result, num) => result + num)
    const isMobile = useMatchMedia()
    return (
        <Typography variant={isMobile ? "h4" : "h3"} color={balance > 0 ? "primary" : "secondary"}>
            BALANCE: <NumberFormat
                value={balance}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                prefix={'$'} /> </Typography>
    )
}

function IncomeTotal({ amountData }) {
    const isMobile = useMatchMedia()
    const income = amountData.filter(f => f > 0).length > 0
        ? amountData.filter(f => f > 0).reduce((result, num) => result + num)
        : 0
    return (
        <Typography variant={isMobile ? " h6" : "h5"} color={income > 0 ? "primary" : "secondary"}>
           <Chip avatar={<Avatar>I</Avatar>} label="income" color="secondary" /> <NumberFormat value={income}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                prefix={'$'} /> </Typography>
    )
}

function ExpenseTotal({ amountData }) {
    const isMobile = useMatchMedia()
    const expense = amountData.filter(f => f < 0).length > 0
        ? amountData.filter(f => f < 0).reduce((result, num) => result + num)
        : 0
    return (
        <Typography variant={isMobile ? " h6" : "h5"} color={expense > 0 ? "primary" : "secondary"}>
            <Chip avatar={<Avatar>E</Avatar>} label="expense" color="primary" /> <NumberFormat value={expense}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                prefix={'$'} /> </Typography>
    )
}

export function BalanceBoard({ amountData, categoryData, paymentMethodData, loading }) {
    const classes = useDashboardPageStyles();
    const isMobile = useMatchMedia()

    return (
        <div className={classes.innerContainer}>
            <Grid container space={4}>
                <Grid item sm={12}>
                    {loading ? <CircularProgress />
                        : amountData.length > 0 && <BalanceTotal amountData={amountData} />}
                </Grid>
                <Grid item sm={6}>
                    {loading ? <CircularProgress /> :
                        <IncomeTotal amountData={amountData} />}
                </Grid>
                <Grid item sm={6}>
                    {loading ? <CircularProgress /> :
                        <ExpenseTotal amountData={amountData} />}
                </Grid>
                <Grid container space={4} className={isMobile ? classes.hideTableCell : classes.showTableCell}>
                    {categoryData.length > 0 && (
                        <>
                            <Grid item sm={6}>
                                <Pie data={paymentMethodData}
                                    width={200}
                                    height={200}
                                    innerRadius={60}
                                    outerRadius={100}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Pie data={categoryData}
                                    width={200}
                                    height={200}
                                    innerRadius={60}
                                    outerRadius={100}
                                />
                            </Grid>
                        </>
                    )}</Grid>

            </Grid>
        </div>
    )
}